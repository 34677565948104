import React from 'react';

export const HeaderCustom = () => {
  const handleScroll = e => {
    document.getElementById(e.target.dataset.key).scrollIntoView()
  }

  return (
    <header>
      <button onClick={handleScroll} data-key='presentation'>
        <img src="img/logo.png" alt="Logo Ferronnerie Sorabella" style={{ margin: 0 }} data-key='presentation' />
      </button>
      <ul className='nav' id='nav'>
        <li>
          <button onClick={handleScroll} className='nav-button' data-key='creations'>Nos créations</button>
        </li>
        <li>
          <button onClick={handleScroll} className='nav-button' data-key='contact'>Nous contacter</button>
        </li>
      </ul>
    </header>
  );
};