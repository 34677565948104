import React from 'react';

export const Presentation = () => {
  return (
    <div
      id="presentation"
      className='presentation'
      style={{ background: "no-repeat url('img/worker.jpg') center" }}
    >
      <div className='presentation-text'>
        <h1>Bienvenue à la Ferronnerie Sorabella</h1>
        <p>Une ferronnerie artisanale d'exception, perpétuant un héritage familial de père en fils.</p>
        <p>Spécialistes de la création sur mesure, nous donnons vie à vos idées avec passion. Chaque pièce que nous réalisons allie esthétique et robustesse, témoignant de notre expertise séculaire.</p>
        <p>Nous vous offrons des devis gratuits, garantissant transparence et accessibilité.</p>
        <p>Choisissez la Ferronnerie Sorabella pour des créations uniques, alliant tradition, art et qualité.</p>
      </div>
    </div>
  );
};