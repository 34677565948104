import { Footer } from 'antd/es/layout/layout';
import React from 'react';
import { Link } from 'react-router-dom';

export const FooterCustom = () => {
  return (
    <Footer style={{ textAlign: 'center', backgroundColor: '#121212' }}>
      <Link to="/mentions-legales" style={{ color: 'white', textAlign: 'center' }}>Mentions légales</Link>
    </Footer>
  );
};