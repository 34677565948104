import { Image } from 'antd';
import React from 'react';
import { images } from '../../data/imagesData';


export const CreationsPreview = () => {
  const imagesPreview = images.slice(0, 6)
  return (
    <div id='creations' className='creations'>
      <h2 className='creations-title'>Nos créations</h2>
      <div className='creations-photos'>
        <Image.PreviewGroup >
          {
            imagesPreview.map((img, index) => (
              <div className="creations-photo" key={index}>
                <p className='creations-photo-description'>{img.description}</p>
                <Image src={`img/${img.src}`} alt={img.description} />
              </div>
            ))
          }
        </Image.PreviewGroup>
      </div>
    </div>
  );
};