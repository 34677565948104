export const images = [
  {
    src: 'image2.jpg',
    description: 'Bar sur mesure avec plateau bois',
    category: 'bar'
  },
  {
    src: 'image3.jpg',
    description: 'Pergola avec fer à T',
    category: 'pergola'
  },
  {
    src: 'image4.jpg',
    description: 'Verrière avec fenêtre coulissante à la verticale',
    category: 'verrière'
  },
  {
    src: 'image5.jpg',
    description: 'Tour de piscine avec portillon double battant',
    category: 'garde-corps'
  },
  {
    src: 'image6.jpg',
    description: 'Pergola avec fer à T',
    category: 'pergola'
  },
  {
    src: 'image7.jpg',
    description: 'Portail coulissant avec frise centrale',
    category: 'portail'
  },
  {
    src: 'image8.jpg',
    description: 'Garde-corps arrondis',
    category: 'garde-corps'
  },
  {
    src: 'image9.jpg',
    description: 'Garde-corps droits',
    category: 'garde-corps'
  },
  {
    src: 'image10.jpg',
    description: 'Portillon plein en tube de 40⌀',
    category: 'portail'
  },
  {
    src: 'image11.jpg',
    description: 'Verrière',
    category: 'verrière'
  },
  {
    src: 'image12.jpg',
    description: 'Pergola avec feuilles de vignes artisanales et pommes de pin',
    category: 'pergola'
  },
]