import React from 'react';
import { CopyOutlined, PhoneFilled, MailFilled, CheckOutlined } from '@ant-design/icons'
import { Button, notification } from 'antd';

export const Contact = () => {
  const copyToClipboard = text => {
    navigator.clipboard.writeText(text)
    notification.success({
      message: 'Copié !',
      icon: (<CheckOutlined style={{ color: 'green' }} />),
      closeIcon: false,
      placement: 'top',
      duration: 1.5,
    })
  }

  return (
    <div className='contact' id='contact'>
      <div className='contact-section'>
        <h2 className='contact-title'>Contactez-nous !</h2>
        <div className="contact-text">
          <p>Nous réalisons vos devis gratuitement !</p>
          <div className="contact-coordonates">
            <p>{<PhoneFilled className='contact-icon' />} 06 50 96 04 10</p>
            <Button
              icon={<CopyOutlined />}
              size='large'
              onClick={() => copyToClipboard('0650960410')}
            ></Button>
          </div>
          <div className="contact-coordonates">
            <p><MailFilled className='contact-icon' /> ferronnerie.sorabella@gmail.com</p>
            <Button
              icon={<CopyOutlined />}
              size='large'
              onClick={() => copyToClipboard('ferronnerie.sorabella@gmail.com')}
            ></Button>
          </div>
        </div>
      </div>
      <div className='contact-section'>
        <h2 className='contact-title'>Rendez-nous visite !</h2>
        <iframe
          title='localisation de la ferronnerie'
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2883.225226038398!2d5.366234076527615!3d43.72664784767681!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12ca1fe4dbaf1b35%3A0x6dd7d44b98aed4b9!2sFerronnerie%20Sorabella!5e0!3m2!1sen!2sfr!4v1705919674701!5m2!1sen!2sfr"
          height="500"
          style={{ border: 0 }}
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          className='contact-iframe'
        ></iframe>
      </div>
    </div>
  );
};