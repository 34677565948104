import { Layout } from 'antd';
import React from 'react';
import { HeaderCustom } from '../components/HeaderCustom';
import { ContentCustom } from '../components/ContentCustom';
import { FooterCustom } from '../components/FooterCustom';

export const Home = () => {
  return (
    <Layout>
      <HeaderCustom />
      <ContentCustom />
      <FooterCustom />
    </Layout>
  );
};