import { Content } from 'antd/es/layout/layout';
import React from 'react';
import { Presentation } from './contentComponents/Presentation';
import { CreationsPreview } from './contentComponents/CreationsPreview';
import { Contact } from './contentComponents/Contact';

export const ContentCustom = () => {
  return (
    <Content style={{ textAlign: 'center', backgroundColor: 'transparent' }}>
      <Presentation />
      <CreationsPreview />
      <Contact />
    </Content>
  );
};