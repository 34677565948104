import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';

export const LegalMentions = () => {
  return (
    <div className='legal-mentions'>
      <div className='legal-mentions-text'>
        <h2 className='legal-mentions-title'>Mentions légales</h2>
        <p>Dénomination sociale : Sorabella Julien (EI)</p>
        <p>Siège social : 40b, avenue de la gare, 84160, Cadenet</p>
        <p>SIREN : 811641844</p>
        <p>N° TVA : FR19811641844</p>
        <p>Mail : ferronnerie.sorabella@gmail.com</p>
        <p>N° Téléphone : 0650960410</p>
        <h2 className='legal-mentions-title'>Hébergeur</h2>
        <p>Dénomination sociale : OVH</p>
        <p>Siège social : 2 Rue Kellermann, 59100 ROUBAIX</p>
        <p>N° Téléphone : 0899701761</p>
        <h2 className='legal-mentions-title'>Données Personnelles</h2>
        <p>Aucune donnée personnelle n'est conservé</p>
      </div>
      <Link to='/' className='legal-mentions-button'><ArrowLeftOutlined /> Retour à l'accueil</Link>
    </div>
  );
};